import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { strapiURL } from '../../../../config'
import { Link, navigate } from 'gatsby'
import CsvDownloader from 'react-csv-downloader'
import { message, Table, Select } from 'antd'
import { dateFormatHandler } from '../../../../functions'

const { Option } = Select
const JobApplicationsComponent = ({ location }) => {
  let jobId = location?.pathname?.split('/')[3]
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeSort, setActiveSort] = useState('appliedOn')
  useEffect(() => {
    setLoading(true)
    if (jobId) {
      axios
        .get(strapiURL + `/applications/jobs/${jobId}`)
        .then(response => {
          let _data = []
          response?.data?.forEach(item => {
            _data.push({
              ...item,
              key: item.id,
            })
          })
          sortHandler(_data, activeSort)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
        })
    }
  }, [jobId])

  const jobCols = [
    {
      title: 'Candidate Name',
      dataIndex: 'title',
      key: 'title',
      render: (text, record, index) => {
        return (
          <div>
            <p className="text-last font-bold font-sans mb-0">
              {record?.candidate_profile?.first_name +
                ' ' +
                record?.candidate_profile?.last_name || 'Not Specified'}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Applied on',
      dataIndex: 'applied_on',
      key: 'applied_on',
      render: (text, record, index) => {
        return (
          <div>
            <p className="text-last font-bold font-sans mb-0">
              {dateFormatHandler(record?.appliedAt)}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        return (
          <div>
            <p
              className={`${
                record.status === 'Active'
                  ? ' bg-[#FEF8EA] text-[#756F63]'
                  : record.status === 'Rejected'
                  ? 'text-[#C40000] bg-[#FFF0F0]'
                  : 'bg-[#DCE9EE] text-[#00567C]'
              } inline-block text-location text-last font-sans mb-0 px-2 rounded`}
            >
              {record.status}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div>
            <p
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
              onClick={() => {
                navigate(
                  `/employer-dashboard/jobs/${jobId}/applications/application/${record.id}`
                )
              }}
            >
              View
            </p>
          </div>
        )
      },
    },
  ]

  const sortHandler = (appList, val) => {
    let _applications = [...appList]
    if (!_applications.length) {
      message.info('No Application Found.')
      return
    }
    if (val === 'appliedOn') {
      _applications.sort(
        (a, b) => new Date(b.appliedAt) - new Date(a.appliedAt)
      )
    }
    setApplications(_applications)
  }

  const csvHeader = [
    {
      id: 'jobTitle',
      displayName: 'Job Title',
    },
    {
      id: 'jobId',
      displayName: 'Job ID',
    },
    {
      id: 'jobCode',
      displayName: 'Job Code',
    },
    {
      id: 'jobLocation',
      displayName: 'Job Location',
    },
    {
      id: 'canFirstName',
      displayName: 'Candidate First Name',
    },
    {
      id: 'canLastName',
      displayName: 'Candidate Last Name',
    },
    {
      id: 'canEmail',
      displayName: 'Candidate Email',
    },
    {
      id: 'canSalesExperience',
      displayName: 'Candidate Sales Experience',
    },
    {
      id: 'canMedicalExperience',
      displayName: 'Candidate Years of Medical Experience',
    },
  ]

  const exportHandler = async () => {
    const data = applications.map(item => {
      return {
        jobTitle: item?.job?.title,
        jobId: item?.job?.id,
        jobCode: item?.job?.job_code,
        jobLocation: Array.isArray(item?.job?.location)
          ? item?.job?.location[0]?.location?.formattedAddress
          : item?.job?.location?.location?.formattedAddress,
        canFirstName: item?.candidate_profile?.user?.first_name,
        canLastName: item?.candidate_profile?.user?.last_name,
        canEmail: item?.candidate_profile?.user?.email,
        canSalesExperience: item?.candidate_profile?.general_sales_experience,
        canMedicalExperience: item?.candidate_profile?.medical_sales_experience,
      }
    })

    return data
  }

  return (
    <div>
      <div className="flex justify-between items-end">
        <h1 className="font-playfair text-headingThree text-merlot">
          Applications
        </h1>
      </div>
      <div className="rounded-lg bg-white shadow-profileCard mt-6 lg:mt-12">
        <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-application.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              {applications.length ? applications[0]?.job?.title : ''}
            </h1>
          </div>
          <div className="flex items-center">
            <div className="hidden md:block relative border border-[#C4C4C4] rounded focus:border-merlot px-2 py-1 sort-select mr-4 sort-by-text">
              <span className="absolute z-10 font-sans font-semibold text-[#6A6A6A] mt-1">
                Sort By:
              </span>
              <Select
                defaultValue={activeSort}
                onChange={val => {
                  sortHandler(applications, val)
                }}
                className="bg-transparent z-20 relative"
              >
                <Option value="appliedOn">Applied on</Option>
              </Select>
            </div>
            <div className="flex items-center">
              <button
                className="bg-voyage py-3 px-3 font-sans text-white text-last font-bold rounded min-w-[82px]"
                onClick={() => navigate('/employer-dashboard/jobs/manage/')}
              >
                Manage Jobs
              </button>
              {applications?.length ? (
                <CsvDownloader
                  className="block bg-bgSecondaryButton py-3 px-3 text-voyage font-bold rounded text-xs font-sans ml-2"
                  filename="applications.csv"
                  wrapColumnChar='"'
                  columns={csvHeader}
                  datas={exportHandler}
                  text="EXPORT"
                />
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className="w-full min-h-700px bg-no-repeat bg-cover"
            style={{ backgroundImage: `url(/loaders/manage-jobs-loader.png)` }}
          />
        ) : (
          <Table
            className="hide-table-header-background hide-table-header-cols-border table-with-pagination"
            columns={jobCols}
            dataSource={applications}
            bordered={false}
          />
        )}
      </div>
    </div>
  )
}
export default JobApplicationsComponent
